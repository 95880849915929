import React from 'react';
import {Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import {FaqSection,
    FaqAccordianWrapper,
    AccordianSectionHeading} from './faq.style'
import AccordianCustom from './AccordianCustom';
import { useStaticQuery, graphql } from 'gatsby';

  
const Faq =()=>{
    const JSONData  = useStaticQuery(graphql`
        query{
            faqPageJson {
                FAQ {
                    FaqSectionSubHeading1
                    AccordianCustom1{
                        title
                        description
                    }
                    FaqSectionSubHeading2
                    AccordianCustom2{
                        title
                        description
                    }
                    FaqSectionSubHeading3
                    AccordianCustom3{
                        title
                        description
                    }
                }
            }
        }
    `);
    return (
        <FaqSection>
            <Container>
                <FaqAccordianWrapper>
                    <Row>
                        <Col md="12">
                        <AccordianSectionHeading>{JSONData.faqPageJson.FAQ.FaqSectionSubHeading1}</AccordianSectionHeading>
                        {
                            JSONData.faqPageJson.FAQ.AccordianCustom1.map((item,idx) => {
                            return <AccordianCustom 
                                title={item.title}
                                description= {item.description} 
                            />
                            })
                        }
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                        <AccordianSectionHeading>{JSONData.faqPageJson.FAQ.FaqSectionSubHeading2}</AccordianSectionHeading>
                        {
                            JSONData.faqPageJson.FAQ.AccordianCustom2.map((item,idx) => {
                            return <AccordianCustom 
                                title={item.title}
                                description= {item.description} 
                            />
                            })
                        }
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                        <AccordianSectionHeading>{JSONData.faqPageJson.FAQ.FaqSectionSubHeading3}</AccordianSectionHeading>
                        {
                            JSONData.faqPageJson.FAQ.AccordianCustom3.map((item,idx) => {
                            return <AccordianCustom 
                                title={item.title}
                                description= {item.description} 
                            />
                            })
                        }
                        </Col>
                    </Row>
                </FaqAccordianWrapper>
            </Container>
        </FaqSection>
    );
}
export default Faq;