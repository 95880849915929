import styled from 'styled-components';
import {Commonh3} from '../../../components/common.style';
import {ChevronDown} from 'styled-icons/boxicons-regular/ChevronDown';
import {ChevronUp} from 'styled-icons/boxicons-regular/ChevronUp';
import {device} from '../../../components/device';

export const FaqSection = styled.section`
    padding:85px 0px;
    border-bottom:1px solid #ccc;

    @media ${device.tablet} {
        padding:65px 10px;
    }
`;
export const AccordianSectionHeading = styled(Commonh3)`
    text-align:left;
    font-size:32px;
    margin-bottom:30px;
    margin-top:15px;
`;

export const FaqAccordianWrapper = styled.div`
    max-width:900px;
    margin:auto;
    
`;

export const AccordianRow = styled.div`
`;

export const Accordian = styled.div`
    overflow: hidden;
    margin-bottom:15px;
`;

export const AccordianTitleCol = styled.div`
    display:flex;
    // align-items:center;
    background:#f5f5f5;
    transition: all .5s;

    :hover{
        background:#f5f5f5;
        transition: all .5s;
    }
`;

export const AccordianTitle = styled.h3`
    padding: 15px 10px 15px 20px;
    font-size:18px;
    overflow:hidden;
    margin-bottom:0px;
    cursor:pointer;
    flex-grow: 1;

    @media ${device.tablet} {
    }
`;

export const AccordianContentCol = styled.div`
    padding: 0px 40px 15px 20px;
    background:#f5f5f5;

    ${props => props.isActive &&`
        
    `}

    ${props => !props.isActive &&`

    `}
`;
export const AccordianContent = styled.p`
    color: #565759;
    font-size:15px;
    margin-bottom:0px;
    padding:10px 0px;
`;

export const IconHolder = styled.div`
    background:#2560d4;
    display:flex;
    align-items:center;
`;

export const PlusIcon = styled(ChevronDown)`
    color: #fff;
    width: 25px;
    margin-right: 8px;
    margin-left: 8px;
    flex-shrink:0;
    
`;

export const MinusIcon = styled(ChevronUp)`
    color: #fff;
    width: 25px;
    margin-right: 8px;
    margin-left: 8px;
    flex-shrink:0;
`;