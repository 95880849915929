import React from "react";
import Banner from "../containers/CommonBanner";
import Headermenu from "../containers/HeaderMenuBS";
import Footer from "../containers/Footer";
import FAQ from "../containers/FAQPage/Faq";
import GlobalStyle from "../components/global-styles";
import "../components/layout.css";
import SEO from "../components/seo";
import BackgroundImg from '../assets/faq-images/banner.jpg';
import Fonts from "../components/fonts";

const FaqPage = () => (
   <div>
       <Fonts/>
       <GlobalStyle />
       <main>
            <SEO 
                title="Faq | Pool Website Template | GatsbyJS Templates | Garnet"
                description="Clean and professional React GatsbyJS Pool Website template from Garnet"
            />
            <Headermenu />
            <Banner
                BackgroundImg={BackgroundImg}
                pageTitle="Frequently Asked Questions"
                breadCrumbsFirst="Home"
                breadCrumbsSecond="FAQ"
                breadCrumbsFirstLink="/"
                breadCrumbsSecondLink="/faq"
            />
            <FAQ />
        </main>
        <Footer />
   </div>
)

export default FaqPage;
